class Slider {
    constructor(blockData, parentEl) {
        this.blockData = blockData;
        this.parentEl = parentEl;
    }
    renderSlider() {
        var _a;
        const h_tag = ((_a = this.blockData.data.options) === null || _a === void 0 ? void 0 : _a.h_lvl) || 'h2';
        const type_params = {
            'block-slider-1': {
                withPreviews: true
            },
            'block-slider-2': {
                type: 'loop',
                autoWidth: true,
                focus: 'center',
            },
            'block-slider-3': {
                type: 'loop',
                autoWidth: true,
                focus: 'center',
                gap: 30
            },
            'block-slider-4': {
                type: 'loop',
            }
        };
        const sliderContainerEl = this.initSplideWithImages(this.blockData.data.images, type_params[this.blockData.data.type]);
        sliderContainerEl.classList.add('block-slider', this.blockData.data.type);
        const blockContainerEl = document.createElement('div');
        blockContainerEl.classList.add('slider-container');
        blockContainerEl.innerHTML = `
        <div class="block-head">
          <div class="text-container title-container">
            <${h_tag} class="title text__contenteditable">
              ${this.blockData.data.title}
            </${h_tag}> 
          </div>
          <div class="text-container subtitle-container">
            <div class="text text__contenteditable">
              ${this.blockData.data.subtitle}
            </div>
          </div>
        </div>  
      `;
        blockContainerEl.appendChild(sliderContainerEl);
        return blockContainerEl;
    }
    initSplideWithImages(images, params = {}) {
        let dafaultParams = {
            perPage: 1,
            pagination: false,
            speed: 700,
            focus: 'center',
            keyboard: 'focused',
            waitForTransition: false,
            updateOnMove: true
        };
        dafaultParams = Object.assign(Object.assign({}, params), dafaultParams);
        let imagesSlidesHTML = '';
        images.forEach((image) => {
            imagesSlidesHTML += `<li class="splide__slide"><img src="${image.big_1920}" /></li>`;
        });
        var div = document.createElement('div');
        div.innerHTML = `
        <div class="splide">
          <div class="splide__slider">
            <div class="splide__arrows">
              <button class="splide__arrow splide__arrow--prev"></button>
              <button class="splide__arrow splide__arrow--next"></button>
            </div>
            <div class="splide__arrows--clone">
              <button class="splide__arrow--clone splide__arrow--prev--clone"></button>
              <button class="splide__arrow--clone splide__arrow--next--clone"></button>
            </div>
            <div class="splide__index"></div>
            <div class="splide__track">
              <ul class="splide__list">
                ${imagesSlidesHTML}
              </ul>
            </div>
          </div>
          <div class="splide__previews--container"></div>
        </div>`;
        const splideContainer = div.querySelector('.splide');
        const splide = new Splide(splideContainer, dafaultParams);
        splideContainer.splide = splide;
        let previewSplideContainer;
        if (params.withPreviews) {
            previewSplideContainer = this.getPreviewSliderContainer(images);
            splideContainer.querySelector('.splide__previews--container').appendChild(previewSplideContainer);
            splide.sync(previewSplideContainer.splide);
        }
        splide.mount();
        splide.on('click', ({ index }) => splide.go(index));
        const indexContainerEl = splideContainer.querySelector('.splide__index');
        const updIndex = (i) => indexContainerEl.innerHTML = `<i>${i + 1}</i><i></i><i>${images.length}</i>`;
        updIndex(splide.index);
        splide.on('move', i => updIndex(i));
        
        return splideContainer;
    }
    getPreviewSliderContainer(images) {
        let dafaultParams = {
            waitForTransition: false,
            pagination: false,
            speed: 300,
            fixedWidth: 100,
            fixedHeight: 100,
            keyboard: 'focused',
            cover: true,
            isNavigation: true,
            updateOnMove: true,
            trimSpace: true,
        };
        let imagesSlidesHTML = '';
        images.forEach(image => imagesSlidesHTML += `<li class="splide__slide"><img src="${image.big_1920}" /></li>`);
        var div = document.createElement('div');
        div.innerHTML = `
        <div class="splide-previews">
          <div class="splide__slider">
            <div class="splide__arrows">
              <button class="splide__arrow splide__arrow--prev"></button>
              <button class="splide__arrow splide__arrow--next"></button>
            </div>
            <div class="splide__track">
              <ul class="splide__list">
                ${imagesSlidesHTML}
              </ul>
            </div>
          </div>
        </div>`;
        const splideContainer = div.querySelector('.splide-previews');
        splideContainer.splide = new Splide(splideContainer, dafaultParams).mount();
        return splideContainer;
    }
}
